import React from 'react'
import Section from './section'
import Grid from '@material-ui/core/Grid'
import Content from './html-content'
import Img from 'gatsby-image'
import styled from '@emotion/styled'

const SectionImage = styled(Img)`
  /* max-width: ${props => props.theme.spacing(100)}px; */
  /* margin: 0 auto; */
`

export const Image = ({ image }) => (
  <SectionImage fluid={image.childImageSharp.fluid} />
)

export default ({ image, text, maxWidth }) => {
  return (
    <Section maxWidth={maxWidth}>
      <Grid container spacing={4} >
        <Grid item sm={4}>
          <Image image={image} />
        </Grid>
        <Grid item sm={8}>
          <Content html={text} />
        </Grid>
      </Grid>
    </Section>
  )
}
