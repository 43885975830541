import React from 'react'
import styled from '@emotion/styled'

const Content = styled.div`
  font-size: 1rem;

  & ul {
    margin: 0 18px;
  }
`

export default ({ html }) => (
  <Content dangerouslySetInnerHTML={{ __html: html }}/>
)
