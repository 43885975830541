import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import HeaderSection from '../components/section-header'
import TextSection from '../components/section-text'
import ImageTextSection from '../components/section-image-text'

export default ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout>
      <HeaderSection {...frontmatter.header} />
      <TextSection text={frontmatter.intro} maxWidth="md" variant="h3" align="center" />
      <ImageTextSection image={frontmatter.image_and_text.image} text={data.markdownRemark.html} />
    </Layout>
  )
}

export const query = graphql`
  query AboutPageQuery($id: String!) {
    markdownRemark(id: {eq: $id }) {
      frontmatter {
        header {
          title
          backgroundColor
          image {
            childImageSharp {
              fluid(maxWidth: 800) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        title
        intro
        image_and_text {
          image {
            childImageSharp {
              fluid(maxWidth: 800) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          text
        }
      }
      html
    }
  }
`